<template>
  <div>
    <PageHeader title="Entities">
      <template v-slot:actions
        ><router-link data-cy="new-entity" class="btn btn-primary mb-3" :to="{ name: 'entity-wizard' }"
          ><i class="fa fa-plus fa-fw"></i> New Entity</router-link
        ></template
      >
    </PageHeader>
    <div class="content">
      <DataTable
        :key="dataTableKey"
        :items="entities"
        :total="totalEntities"
        :loading="loadingAction.list"
        :columns="columns"
        :actions="actions"
        :fetch-items="listEntities"
        default-sort-key="legalName"
        :default-sort-direction="1"
      >
        <template v-slot:legalName="slotProps">
          <router-link class="nav-link" :to="{ name: 'entity-view', params: { id: slotProps.data._id } }">{{ slotProps.data.legalName }}</router-link>
        </template>

        <template v-slot:parentEntityId="slotProps">
          {{ getParentEntity(slotProps.data.parentEntityId) }}
        </template>

        <template v-slot:ultimateParentEntityId="slotProps">
          {{ getParentEntity(slotProps.data.ultimateParentEntityId) }}
        </template>
      </DataTable>
    </div>
    <ConfirmModal
      :open="!!modals.remove"
      title="Delete Entity"
      :text="`Please confirm you would like to remove entity:<br/><br/><strong>${modals.remove.legalName}</strong>`"
      @close="
        () => {
          modals.remove = false;
        }
      "
      @submit="onRemoveEntity"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import DataTable from '@/components/DataTable';
import PageHeader from '@/components/PageHeaderDisplay';
import ConfirmModal from '@/components/ConfirmModal';

export default {
  name: 'EntityList',
  components: {
    ConfirmModal,
    DataTable,
    PageHeader
  },
  data() {
    return {
      allEntities: [],
      modals: {
        remove: false
      },
      dataTableKey: 0
    };
  },
  computed: {
    ...mapGetters({
      entities: 'entity/entities',
      totalEntities: 'entity/total',
      loadingAction: 'entity/loadingAction'
    }),
    actions() {
      return [
        {
          label: 'Edit',
          class: 'btn-primary',
          icon: 'pencil',
          route: 'entity-edit',
          params: { id: '_id' }
        },
        {
          label: 'Delete',
          class: 'btn-danger',
          icon: 'trash',
          onClick: this.onClickDeleteEntity
        }
      ];
    },
    parentEntityOptions() {
      const entities = this.allEntities.map(e => ({ label: e.legalName, value: e._id }));

      entities.sort((a, b) => a.label.localeCompare(b.label));

      return entities;
    },
    columns() {
      return [
        { name: 'Name', code: 'legalName', search: true },
        {
          name: 'Parent Entity',
          code: 'parentEntityId',
          search: true,
          searchType: 'dropdown',
          searchOptions: this.parentEntityOptions,
          sort: false
        },
        {
          name: 'Ultimate Parent Entity',
          code: 'ultimateParentEntityId',
          search: true,
          searchType: 'dropdown',
          searchOptions: this.parentEntityOptions,
          sort: false
        },
        { name: 'Company No.', code: 'companyNumber', search: true }
      ];
    }
  },
  async mounted() {
    await this.listCountries();
    await this.listEntities();

    this.allEntities = this.entities;
  },
  methods: {
    ...mapActions({
      listEntities: 'entity/list',
      removeEntity: 'entity/remove',
      listCountries: 'util/listCountries'
    }),
    getParentEntity(parentEntityId) {
      const parentEntity = this.allEntities.find(e => e._id === parentEntityId);

      if (!parentEntity) {
        return 'N/A';
      } else {
        return parentEntity.legalName;
      }
    },
    onClickDeleteEntity(entity) {
      this.modals.remove = entity;
    },
    async onRemoveEntity() {
      const { _id, legalName } = this.modals.remove;
      this.removeEntity({ id: _id });
      await this.listEntities();
      this.dataTableKey += 1;
      this.$toasted.success(`Entity "${legalName}" deleted successfully`);
      this.modals.remove = false;
    }
  }
};
</script>
